var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('utility-results',{attrs:{"chart-data":_vm.chartData}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('dashboard-card',{attrs:{"title":"Evolución utilidad mes actual"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries
                ([
                  {category: 'currentMonth', field: 'soldPurchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.soldPurchases},
                  {category: 'currentMonth', field: 'soldSales', queryMethod: _vm.getSoldSales, queryFilter: _vm.currentMonthFilters.soldSales},
                  {category: 'currentMonth', field: 'utilityCredits', queryMethod: _vm.getUtilityCredits, queryFilter: _vm.currentMonthFilters.utilityCredits},
                  {category: 'prevMonth', field: 'soldPurchases', queryMethod: _vm.getPurchases, queryFilter: _vm.prevMonthFilters.soldPurchases},
                  {category: 'prevMonth', field: 'soldSales', queryMethod: _vm.getSoldSales, queryFilter: _vm.prevMonthFilters.soldSales},
                  {category: 'prevMonth', field: 'utilityCredits', queryMethod: _vm.getUtilityCredits, queryFilter: _vm.prevMonthFilters.utilityCredits},
                ])}),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries\n                ([\n                  {category: 'currentMonth', field: 'soldPurchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.soldPurchases},\n                  {category: 'currentMonth', field: 'soldSales', queryMethod: getSoldSales, queryFilter: currentMonthFilters.soldSales},\n                  {category: 'currentMonth', field: 'utilityCredits', queryMethod: getUtilityCredits, queryFilter: currentMonthFilters.utilityCredits},\n                  {category: 'prevMonth', field: 'soldPurchases', queryMethod: getPurchases, queryFilter: prevMonthFilters.soldPurchases},\n                  {category: 'prevMonth', field: 'soldSales', queryMethod: getSoldSales, queryFilter: prevMonthFilters.soldSales},\n                  {category: 'prevMonth', field: 'utilityCredits', queryMethod: getUtilityCredits, queryFilter: prevMonthFilters.utilityCredits},\n                ])}"}],attrs:{"data":[
              {
                records: _vm.chartData.currentMonth.soldPurchases,
                name: 'Compras',
                pathToDate: ['stock', 'soldDate'],
                color: '#13CE66',
                type: 'line',
                pathToValue: ['totalCost'],
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.currentMonth.soldPurchases,
                name: 'Consignaciones',
                pathToDate: ['stockCreated'],
                color: '#FBDA01',
                type: 'line',
                pathToValue: ['totalCost'],
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.currentMonth.soldSales,
                name: 'Ventas',
                pathToDate: ['saleOrder', 'deal', 'stock', 'soldDate'],
                color: '#6185DB',
                type: 'line',
                pathToValue: ['amount'],
              },
              {
                records: _vm.chartData.currentMonth.utilityCredits,
                name: 'Créditos',
                pathToDate: ['evaluation', 'signedDate'],
                color: '#7D67BC',
                type: 'line',
                pathToValue: ['fee'],
              },
            ],"prev-month-data":[
              {
                records: _vm.chartData.prevMonth.soldPurchases,
                name: 'Compras (mes anterior)',
                pathToDate: ['stock', 'soldDate'],
                color: '#13CE66',
                type: 'line',
                pathToValue: ['totalCost'],
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.prevMonth.soldPurchases,
                name: 'Consignaciones (mes anterior)',
                pathToDate: ['stockCreated'],
                color: '#FBDA01',
                type: 'line',
                pathToValue: ['totalCost'],
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.prevMonth.soldSales,
                name: 'Ventas (mes anterior)',
                pathToDate: ['saleOrder', 'deal', 'stock', 'soldDate'],
                color: '#6185DB',
                type: 'line',
                pathToValue: ['amount'],
              },
              {
                records: _vm.chartData.prevMonth.utilityCredits,
                name: 'Créditos (mes anterior)',
                pathToDate: ['evaluation', 'signedDate'],
                color: '#7D67BC',
                type: 'line',
                pathToValue: ['fee'],
              }
            ],"data-transformer":_vm.transformDailyUtility,"sort-legends":false,"dates":_vm.dates,"accumulated":"","is-currency":"","add-totals":""}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('dashboard-card',{attrs:{"title":"Desarrollo financiero mensual"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'dailyAccountBalance', queryMethod: _vm.getDailyAccountBalance, queryFilter: _vm.currentMonthFilters.startEndDate},
                {category: 'prevMonth', field: 'dailyAccountBalance', queryMethod: _vm.getDailyAccountBalance, queryFilter: _vm.prevMonthFilters.startEndDate},
                {category: 'currentMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.currentMonthFilters.dailyStock},
                {category: 'prevMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.prevMonthFilters.dailyStock},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'dailyAccountBalance', queryMethod: getDailyAccountBalance, queryFilter: currentMonthFilters.startEndDate},\n                {category: 'prevMonth', field: 'dailyAccountBalance', queryMethod: getDailyAccountBalance, queryFilter: prevMonthFilters.startEndDate},\n                {category: 'currentMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: currentMonthFilters.dailyStock},\n                {category: 'prevMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: prevMonthFilters.dailyStock},\n              ])\n            }"}],attrs:{"records":_vm.bankAccountsData.currentMonth,"prev-month-records":_vm.bankAccountsData.prevMonth,"path-to-date":['date'],"null-zero-values":true,"dates":_vm.dates,"is-currency":true,"data-transformer":_vm.transformDailyBalance,"path-to-value":['accountBalance'],"path-to-category":['type'],"display-means":true,"sort-legends":false,"total-sufix":"prom./día","label-sufix":"prom./día"}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('sales-results',{attrs:{"chart-data":_vm.chartData}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('stock-results',{attrs:{"chart-data":_vm.chartData}})],1)],1),_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Acumulado de negocios"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries
                ([
                  {category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate},
                  {category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate},
                  {category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.currentMonthFilters.signedCredits},
                  {category: 'prevMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.prevMonthFilters.stockCreatedDate},
                  {category: 'prevMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.prevMonthFilters.startEndDate},
                  {category: 'prevMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.prevMonthFilters.signedCredits},
                ])}),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries\n                ([\n                  {category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate},\n                  {category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate},\n                  {category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: currentMonthFilters.signedCredits},\n                  {category: 'prevMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: prevMonthFilters.stockCreatedDate},\n                  {category: 'prevMonth', field: 'sales', queryMethod: getSales, queryFilter: prevMonthFilters.startEndDate},\n                  {category: 'prevMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: prevMonthFilters.signedCredits},\n                ])}"}],attrs:{"data":[
              {
                records: _vm.chartData.currentMonth.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'line',
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.currentMonth.purchases,
                name: 'Consignaciones',
                pathToDate: ['stockCreated'],
                color: '#FAD901',
                type: 'line',
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.currentMonth.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'line',
              },
              {
                records: _vm.chartData.currentMonth.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'line',
              },
            ],"prev-month-data":[
              {
                records: _vm.chartData.prevMonth.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'line',
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.prevMonth.purchases,
                name: 'Consignaciones',
                pathToDate: ['stockCreated'],
                color: '#FAD901',
                type: 'line',
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.prevMonth.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'line',
              },
              {
                records: _vm.chartData.prevMonth.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'line',
              },
            ],"dates":_vm.dates,"show-total":false,"accumulated":true,"filter-zero-legends":false,"sort-legends":false}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Utilidad proyectada stock actual"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"path-to-category":['status', 'status'],"path-to-subcategory":['stock', 'stockDetail', 'acquisitionType'],"path-to-value":['projectedUtility'],"data-transformer":_vm.setProjectedUtility,"sort-legends":false,"sort-categories":"","is-currency":""}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Utilidad proyectada stock actual por adquisición"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"path-to-category":['stock', 'stockDetail', 'acquisitionType'],"path-to-subcategory":['status', 'status'],"path-to-value":['projectedUtility'],"data-transformer":_vm.setProjectedUtility,"sort-legends":false,"sort-categories":"","is-currency":""}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Utilidad proyectada stock actual por antigüedad"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"path-to-subcategory":['status', 'status'],"path-to-value":['projectedUtility'],"data-transformer":_vm.setProjectedUtility,"criterias":_vm.stockByAgeCriterias,"sort-legends":false,"sort-categories":"","is-currency":""}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Créditos por canal"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.currentMonthFilters.signedCredits})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: currentMonthFilters.signedCredits})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.signedCredits,"path-to-category":['channel'],"path-to-subcategory":['executive'],"small-chart":true,"data-transformer":_vm.normalicePurchases}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Ventas por adquisición"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.sales,"path-to-category":['acquisitionType'],"path-to-subcategory":['channel'],"small-chart":true,"data-transformer":_vm.normaliceSales}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Ventas por canal"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.sales,"path-to-category":['channel'],"path-to-subcategory":['executive'],"small-chart":true,"data-transformer":_vm.normaliceSales}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Firmados por financiera"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'signedLoans', queryMethod: _vm.getSignedLoans, queryFilter: _vm.currentMonthFilters.signedLoans})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'signedLoans', queryMethod: getSignedLoans, queryFilter: currentMonthFilters.signedLoans})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.signedLoans,"filtering-method":record => record.financing.saleOrder.deal.stock.soldDate && record.financing.saleOrder.deal.lead?.status?.status?.name === 'win',"path-to-category":['financing', 'evaluations', '0', 'agreement', 'person'],"data-transformer":records => {
              return records
                .map(record => record.evaluations.nodes)
                .flat()
            },"detail-elements-generator":record => {
              const { deal } = record.financing.saleOrder
              const { auto } = deal
              const brand = auto.versionYear.version.model.brand.name
              const model = auto.versionYear.version.model.name
              const version = auto.versionYear.version.name
              const year = auto.versionYear.version.year

              const autoModel = [brand, model, version, year].join(' ')
              const plate = auto.registrationPlate

              const executive = deal.lead.executive.person
              const executiveName = [executive.firstName, executive.surname].join(' ')

              const fee = record.financing?.evaluations?.[0]?.offers?.[0]?.fee

              return [
                { title: autoModel, value: plate, bold: true },
                { title: 'Comisión', value: _vm.fixPrice(fee) },
                { title: 'Ejecutivo', value: executiveName },
              ]
            },"path-to-category-title":['alias'],"small-chart":""}})]},proxy:true}])})],1),_c('v-col',[_c('dashboard-card',{attrs:{"title":"Solicitudes de financiamiento actuales"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('loan-applications',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'evaluations', queryMethod: _vm.getFinancing, queryFilter: _vm.currentMonthFilters.loanApplications})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'evaluations', queryMethod: getFinancing, queryFilter: currentMonthFilters.loanApplications})\n            }"}],attrs:{"loan-evaluations":_vm.chartData.currentMonth.evaluations}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Créditos por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.currentMonthFilters.signedCredits})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: currentMonthFilters.signedCredits})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.signedCredits,"records-settings":_vm.chartData.settings.loanEvaluations,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixCreditFields,"path-to-category":['executive'],"path-to-category-title":['fullName']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Reservas por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: _vm.getReservesQuery, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: getReservesQuery, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.reservesQuery,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReservesQuery,"path-to-category":['executive'],"path-to-category-title":['name'],"filtering-criteria":record => record.closingReason?.type?.name === 'successful'}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Ventas por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.sales,"records-settings":_vm.chartData.settings.sale,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixSaleFields,"path-to-category":['executive'],"path-to-category-title":['name']}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Detalle de créditos"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'signedLoansByCreated', queryMethod: _vm.getSignedLoans, queryFilter: _vm.currentMonthFilters.signedLoansByCreated})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'signedLoansByCreated', queryMethod: getSignedLoans, queryFilter: currentMonthFilters.signedLoansByCreated})\n            }"}],attrs:{"sorting-method":_vm.sortBySignedDate,"filtering-method":record => record.financing.saleOrder.deal.stock.soldDate && record.financing.saleOrder.deal.lead?.status?.status?.name === 'win',"records":_vm.chartData.currentMonth.signedLoansByCreated,"records-settings":_vm.chartData.settings.loanEvaluations,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":records => {
              return records
                .map(record => record.evaluations.nodes)
                .flat()
                .map(record => {
                  const { deal } = record.financing.saleOrder
                  const { auto } = deal
                  const brand = auto.versionYear.version.model.brand.name
                  const model = auto.versionYear.version.model.name
                  const version = auto.versionYear.version.name
                  const year = auto.versionYear.version.year

                  const autoModel = [brand, model, version, year].join(' ')
                  const plate = auto.registrationPlate

                  const executive = deal.lead.executive.person
                  const executiveName = [executive.firstName, executive.surname].join(' ')

                  return {
                    ...record,
                    carFullName: autoModel,
                    plate,
                    executiveFullName: executiveName,
                    signedDate: _vm.dayjs(
                      record?.evaluations?.nodes?.[0]?.financing?.evaluations?.[0]?.signedDate
                    ).format('DD/MM/YYYY'),
                    subtitleIcon: record.financing.saleOrder.deal.stock.stockDetail.acquisitionType.name === 'consignment'
                      ? {
                        icon: 'mdi-car-key',
                        text: 'Consignación',
                      } : undefined,
                  }
                })
            },"path-to-subtitle-icon":['subtitleIcon'],"path-to-details":[
              {
                name: ['executiveFullName'],
                path: ['signedDate'],
              },
              {
                name: 'Financiera',
                path: ['financing', 'evaluations', '0', 'agreement', 'person', 'alias'],
              },
            ],"path-to-title":['carFullName'],"path-to-subtitle":['plate']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Detalle de reservas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reserves', queryMethod: _vm.getReserves, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reserves', queryMethod: getReserves, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"sorting-method":_vm.sortByCreated,"records":_vm.chartData.currentMonth.reserves,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReserves,"path-to-details":[
              {
                name: ['executive', 'name'],
                path: ['createdAt'],
              },
              {
                name: 'Monto de reserva',
                path: ['reserveAmount'],
              },
              {
                name: 'Fecha de entrega',
                path: ['deliveryDate'],
              },
            ],"path-to-title":['autoDescription'],"path-to-subtitle":['registrationPlate'],"filtering-criteria":record => record.closingReason?.type?.name === 'successful',"path-to-subtitle-icon":['subtitleIcon']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Detalle de ventas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"sorting-method":_vm.sortBySoldDate,"records":_vm.chartData.currentMonth.sales,"records-settings":_vm.chartData.settings.sale,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixSaleFields,"path-to-details":[
              {
                name: ['executive', 'name'],
                path: ['soldDate'],
              },
              {
                name: 'Precio de venta',
                path: ['saleAmount'],
              },
            ],"path-to-title":['carFullName'],"path-to-subtitle":['registrationPlate'],"path-to-subtitle-icon":['subtitleIcon']}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('credits-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'creditsReports', queryMethod: _vm.getCreditsReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'creditsReports', queryMethod: getCreditsReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.creditsReports}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('reserve-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reservesReports', queryMethod: _vm.getReserveReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reservesReports', queryMethod: getReserveReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.reservesReports}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('sales-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'salesReports', queryMethod: _vm.getSalesReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'salesReports', queryMethod: getSalesReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.salesReports}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('dashboard-card',{attrs:{"title":"Negocios mes actual"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate},
                {category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate},
                {category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.currentMonthFilters.signedCredits},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate},\n                {category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate},\n                {category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: currentMonthFilters.signedCredits},\n              ])\n            }"}],attrs:{"data":[
              {
                records: _vm.chartData.currentMonth.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'bar',
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.currentMonth.purchases,
                name: 'Consignaciones',
                pathToDate: ['stockCreated'],
                color: '#FBDA01',
                type: 'bar',
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.currentMonth.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'bar',
              },
              {
                records: _vm.chartData.currentMonth.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'bar',
              },
            ],"prev-month-data":[],"dates":_vm.dates,"filter-zero-legends":false,"sort-legends":false}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Evolución negocios último año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries([
                {category: 'lastYear', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.lastYearFilters.stockCreatedDate},
                {category: 'lastYear', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.lastYearFilters.startEndDate},
                {category: 'lastYear', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.lastYearFilters.signedCredits},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries([\n                {category: 'lastYear', field: 'purchases', queryMethod: getPurchases, queryFilter: lastYearFilters.stockCreatedDate},\n                {category: 'lastYear', field: 'sales', queryMethod: getSales, queryFilter: lastYearFilters.startEndDate},\n                {category: 'lastYear', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: lastYearFilters.signedCredits},\n              ])\n            }"}],attrs:{"data":[
              {
                records: _vm.chartData.lastYear.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'bar',
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.lastYear.purchases,
                name: 'Consignaciones',
                pathToDate: ['stockCreated'],
                color: '#FBDA01',
                type: 'bar',
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.lastYear.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'bar',
              },
              {
                records: _vm.chartData.lastYear.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'bar',
              },
            ],"prev-month-data":[],"monthly":true,"dates":_vm.lastYearDates,"filter-zero-legends":false,"sort-legends":false}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Evolución utilidad último año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries
                ([
                  {category: 'lastYear', field: 'soldPurchases', queryMethod: _vm.getPurchases, queryFilter: _vm.lastYearFilters.soldPurchases},
                  {category: 'lastYear', field: 'soldSales', queryMethod: _vm.getSoldSales, queryFilter: _vm.lastYearFilters.soldSales},
                  {category: 'lastYear', field: 'utilityCredits', queryMethod: _vm.getUtilityCredits, queryFilter: _vm.lastYearFilters.utilityCredits},
                ])}),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries\n                ([\n                  {category: 'lastYear', field: 'soldPurchases', queryMethod: getPurchases, queryFilter: lastYearFilters.soldPurchases},\n                  {category: 'lastYear', field: 'soldSales', queryMethod: getSoldSales, queryFilter: lastYearFilters.soldSales},\n                  {category: 'lastYear', field: 'utilityCredits', queryMethod: getUtilityCredits, queryFilter: lastYearFilters.utilityCredits},\n                ])}"}],attrs:{"data":[
              {
                records: _vm.chartData.lastYear.soldPurchases,
                name: 'Compras',
                pathToDate: ['stock', 'soldDate'],
                color: '#13CE66',
                type: 'line',
                pathToValue: ['totalCost'],
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.lastYear.soldPurchases,
                name: 'Consignación',
                pathToDate: ['stock', 'soldDate'],
                color: '#FBDA01',
                type: 'line',
                pathToValue: ['totalCost'],
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.lastYear.soldPurchases,
                name: 'Consignaciones',
                pathToDate: ['stock', 'soldDate'],
                color: '#FBDA01',
                type: 'line',
                pathToValue: ['totalCost'],
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.lastYear.soldSales,
                name: 'Ventas',
                pathToDate: ['saleOrder', 'deal', 'stock', 'soldDate'],
                color: '#6185DB',
                type: 'line',
                pathToValue: ['amount'],
              },
              {
                records: _vm.chartData.lastYear.utilityCredits,
                name: 'Créditos',
                pathToDate: ['evaluation', 'signedDate'],
                color: '#7D67BC',
                type: 'line',
                pathToValue: ['fee'],
              },
            ],"prev-month-data":[],"data-transformer":_vm.transformMonthlyUtility,"sort-legends":false,"monthly":true,"dates":_vm.lastYearDates,"is-currency":true}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }